import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import SharedDataContext from './ToolkitSharedDataContext';

export type ReactAppWrapper = React.ComponentType<{
  children: React.ReactNode;
}>;

export type Actions = {
  addComponent: (
    el: Element,
    component: React.ComponentType<any>,
    props: object,
  ) => void;
  addData: (value: object) => void;
};

export type ActionsRef = {
  ref?: Actions;
};

const ToolkitReactRootApp: React.FC<{
  actions: ActionsRef;
  appWrappers?: ReactAppWrapper[];
}> = (props) => {
  const [sharedData, setSharedData] = useState<Record<string, any>>({});
  const [portals, setPortals] = useState<React.ReactNode[]>([]);
  const appWrappers = props.appWrappers || [];

  const addComponent = useCallback(
    (el: Element, component: React.ComponentType<any>, props: object) => {
      // ReactDOM.render(component, targetDOM)，会替换掉 targetDOM 的所有内容
      // ReactDOM.createPortal(component, targetDOM)，会在 targetDOM 中 append children
      // 为了使得新的机制与之前的旧机制行为一致，我们这里手动清空目标 dom 中的内容
      el.innerHTML = '';

      setPortals((old) => [
        ...old,
        ReactDOM.createPortal(React.createElement(component, props), el),
      ]);
    },
    [setSharedData],
  );

  const addData = useCallback(
    (value: object) => {
      setSharedData((old) => ({ ...old, ...value }));
    },
    [setSharedData],
  );

  props.actions.ref = {
    addComponent,
    addData,
  };

  return (
    <SharedDataContext.Provider value={sharedData}>
      {appWrappers.reduce(
        (children, Wrapper) => (
          <Wrapper>{children}</Wrapper>
        ),
        <>{portals}</>,
      )}
    </SharedDataContext.Provider>
  );
};

export default ToolkitReactRootApp;
