import { createContext, useContext } from 'react';

export const SharedDataContext = createContext<Record<string, any>>({});

export default SharedDataContext;

export function useSharedData<T>(key?: string) {
  const sharedData = useContext(SharedDataContext);

  if (key === undefined) {
    return sharedData as T;
  } else {
    return sharedData[key] as T;
  }
}
