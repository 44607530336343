import React from 'react';
import ReactDOM from 'react-dom';
import type { ActionsRef } from './ToolkitReactRootApp';
import ToolkitReactRootApp, { ReactAppWrapper } from './ToolkitReactRootApp';
export { ReactAppWrapper } from './ToolkitReactRootApp';
export { SharedDataContext, useSharedData } from './ToolkitSharedDataContext';

export type ReactComponent = React.ComponentType<any>;
export type AsyncReactComponent = () => Promise<{
  default: React.ComponentType<any>;
}>;

interface FunctionComponentRemoveEl {
  (...args: any[]): any;
  requireEl?: false;
}
interface FunctionComponentRequireEl {
  (el: Element, ...args: any[]): any;
  requireEl: true;
}
export type FunctionComponent =
  | FunctionComponentRemoveEl
  | FunctionComponentRequireEl;
export type AsyncFunctionComponent = () => Promise<{
  default: FunctionComponent;
}>;

type ToolkitConfig = {
  reactComponents?: { [index: string]: ReactComponent };
  asyncReactComponents?: { [index: string]: AsyncReactComponent };

  functionComponents?: { [index: string]: FunctionComponent };
  asyncFunctionComponents?: { [index: string]: AsyncFunctionComponent };

  reactAppWrappers?: ReactAppWrapper[];

  // 将来可以考虑加入 vueComponents
};

type ReactComponentItem = {
  type: 'react';
  async: false;
  component: ReactComponent;
};
type AsyncReactComponentItem = {
  type: 'react';
  async: true;
  component: AsyncReactComponent;
};
type FunctionComponentItem = {
  type: 'function';
  async: false;
  component: FunctionComponent;
};
type AsyncFunctionComponentItem = {
  type: 'function';
  async: true;
  component: AsyncFunctionComponent;
};

export class Toolkit {
  components: Map<
    string,
    | ReactComponentItem
    | AsyncReactComponentItem
    | FunctionComponentItem
    | AsyncFunctionComponentItem
  >;
  reactAppWrappers?: ReactAppWrapper[];

  _reactRootActions?: ActionsRef;

  constructor() {
    this.components = new Map();
    this.reactAppWrappers = undefined;

    this._reactRootActions = undefined;
  }

  get reactRootActions() {
    if (this._reactRootActions === undefined) {
      this._reactRootActions = { ref: undefined };
      ReactDOM.render(
        React.createElement(ToolkitReactRootApp, {
          actions: this._reactRootActions,
          appWrappers: this.reactAppWrappers,
        }),
        document.createElement('div'),
      );
    }

    return this._reactRootActions;
  }

  addComponent(
    name: string,
    item:
      | ReactComponentItem
      | AsyncReactComponentItem
      | FunctionComponentItem
      | AsyncFunctionComponentItem,
  ) {
    if (this.components.has(name)) {
      throw new Error(`组件名称重复：${name}`);
    }
    this.components.set(name, item);
  }

  camelize(s: string): string {
    return s.replace(/-./g, (x) => x[1].toUpperCase());
  }

  async getComponent(el: Element): Promise<
    | {
        type: 'react';
        component: ReactComponent;
      }
    | {
        type: 'function';
        component: FunctionComponent;
      }
    | {
        type: 'SharedData';
        component: undefined;
      }
  > {
    const name = el.getAttribute('data-component');
    if (!name) {
      throw new Error(`${el}: data-component 值为空`);
    }
    el.removeAttribute('data-component');

    if (name === 'SharedData') {
      return { type: 'SharedData', component: undefined };
    }

    const item = this.components.get(name);
    if (!item) {
      throw new Error(`组件不存在：${name}`);
    }

    const component = item.async
      ? (await item.component()).default
      : item.component;

    // @ts-ignore
    return { type: item.type, component };
  }

  async getProps(el: Element): Promise<object> {
    let props: object = {};

    const propsScriptId = el.getAttribute('data-props');
    if (propsScriptId) {
      const propsScriptText =
        document.getElementById(propsScriptId)?.textContent;
      if (!propsScriptText) {
        throw new Error(`组件数据 script 标签不存在：${propsScriptId}`);
      }
      props = { ...JSON.parse(propsScriptText) };
      el.removeAttribute('data-props');
    }

    Array.from(el.attributes).forEach((attr) => {
      if (attr.name.startsWith('data-')) {
        const propName = this.camelize(attr.name.substring(5));
        const propValue = attr.value;
        props = { ...props, [propName]: propValue };
        el.removeAttribute(attr.name);
      }
    });

    return props;
  }

  async renderComponent(el: Element) {
    const { type, component } = await this.getComponent(el);
    const props = await this.getProps(el);

    if (type === 'SharedData') {
      this.reactRootActions.ref?.addData(props);
    } else if (type === 'react') {
      // ReactDOM.render(React.createElement(component, props), el);
      this.reactRootActions.ref?.addComponent(el, component, props);
    } else {
      // 对于 function 组件，根据配置决定是否保留 el
      if (component.requireEl === true) {
        component(el, props);
      } else {
        el.remove();
        component(props);
      }
    }
  }

  /**
   * 有时候我们会动态生成一段 html 并插入 dom 中（例如通过 js 获取到一段内容并插入 dom，实现「加载更多」的效果），
   * 在插入 html 后，可以运行一次 toolkit.renderAll()
   */
  renderAll($context: Document | Element = document) {
    $context.querySelectorAll('[data-component]').forEach(async (el) => {
      this.renderComponent(el);
    });
  }

  run() {
    document.addEventListener('DOMContentLoaded', async () => {
      this.renderAll(document);
    });
  }

  register(config: ToolkitConfig) {
    for (const [name, component] of Object.entries(
      config.reactComponents || {},
    )) {
      this.addComponent(name, { type: 'react', async: false, component });
    }

    for (const [name, component] of Object.entries(
      config.asyncReactComponents || {},
    )) {
      this.addComponent(name, { type: 'react', async: true, component });
    }

    for (const [name, component] of Object.entries(
      config.functionComponents || {},
    )) {
      this.addComponent(name, { type: 'function', async: false, component });
    }

    for (const [name, component] of Object.entries(
      config.asyncFunctionComponents || {},
    )) {
      this.addComponent(name, { type: 'function', async: true, component });
    }

    this.reactAppWrappers = config.reactAppWrappers;

    return this;
  }
}

const globalToolkitInstance = new Toolkit();

/**
 * import { runToolkit } from "@ns/mpa"
 *
 * import ReactComponent1 from "path/to/ReactComponent1"
 * import FunctionComponent1 from "path/to/FunctionComponent1"
 *
 * runToolkit({
 *  reactComponents: {
 *     ReactComponent1,
 *  },
 *  asyncReactComponents: {
 *     ReactComponent2: () => import("path/to/ReactComponent2"),
 *  },
 *  functionComponents: {
 *     FunctionComponent1,
 *  },
 *  asyncFunctionComponents: {
 *     FunctionComponent2: () => import("path/to/FunctionComponent2"),
 *  }
 * })
 *
 * FunctionComponent: 实质就是一个函数，接受一个 props 参数
 *
 * FunctionComponent1.ts:
 *
 * const FooComponent = (props) => {
 *     console.log("component loaded")
 * }
 * export default FooComponent
 */
export const runToolkit = (
  config: ToolkitConfig,
  createInstance: boolean = false,
) => {
  if (createInstance) {
    new Toolkit().register(config).run();
  } else {
    globalToolkitInstance.register(config).run();
  }
};

export const getToolkit = () => globalToolkitInstance;
